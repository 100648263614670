import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Input,
  Button,
  InputAdornment,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Email as MailIcon } from '@material-ui/icons'
import { useTranslation } from 'next-i18next'
import { useRegion } from '../hooks/useRegion'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const noUrl =
  'https://liveklassisk.us19.list-manage.com/subscribe/post?u=42622a08a35e99daaec038795&id=1c193f4d66&f_id=000ab0e4f0'
const dkUrl =
  'https://liveklassisk.us19.list-manage.com/subscribe/post?u=42622a08a35e99daaec038795&id=e7d032bda5&f_id=0006b0e4f0'
const seUrL =
  'https://liveklassisk.us19.list-manage.com/subscribe/post?u=42622a08a35e99daaec038795&id=226174ce3f&f_id=000eb0e4f0'

const getUrl = (region: string): string => {
  switch (region) {
    case 'dk':
      return dkUrl
    case 'se':
      return seUrL
    case 'no':
      return noUrl
    default: {
      console.error(`Unknown region: ${region}`)
      return dkUrl
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(15)
  },
  formInput: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    padding: theme.spacing(0.5, 2, 0.5, 2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: 'auto',
    maxWidth: '500px',
    marginBottom: theme.spacing(2)
  },
  subscribeButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    color: 'white',
    padding: theme.spacing(1, 6, 1, 6),
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  statusMessage: {
    fontSize: '1rem',
    height: '40px',
    marginTop: theme.spacing(3),
    width: '100%',
    textAlign: 'center'
  },
  error: {
    color: 'red',
    '& > a': {
      color: theme.palette.primary.main
    }
  },
  success: {
    color: 'green'
  }
}))

interface NewsLetterProps {}

const Newsletter: React.FC<NewsLetterProps> = ({}) => {
  const classes = useStyles()
  const [email, setEmail] = useState(undefined)
  const region = useRegion()
  const { t } = useTranslation()

  return (
    <MailchimpSubscribe
      url={getUrl(region)}
      render={({ subscribe, message, status, loading }) => (
        <Grid
          className={classes.root}
          container
          spacing={3}
          justifyContent='center'
        >
          <Grid item xs={12}>
            <Typography className={classes.heading} variant='h2' align='center'>
              {t('Get news about the best classical concerts in the country')}
            </Typography>
          </Grid>
          <Grid item md={4} sm={7} xs={10}>
            <Input
              placeholder={t('E-mail address')}
              className={classes.formInput}
              fullWidth
              disableUnderline
              onChange={(e) => setEmail(e.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  <MailIcon color='primary' />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item md={2} sm={7} xs={10}>
            <Button
              fullWidth
              className={classes.subscribeButton}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault()
                subscribe({ EMAIL: email })
              }}
            >
              {t('Subscribe')}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
          <div className={classes.statusMessage}>
            {status === 'error' && (
              <div
                className={classes.error}
                dangerouslySetInnerHTML={{ __html: 'Something went wrong' }}
              />
            )}
            {status === 'success' && (
              <div className={classes.success}>
                {t('Thank you for subscribing!')}
              </div>
            )}
          </div>
        </Grid>
      )}
    />
  )
}

export default Newsletter
