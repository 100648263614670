import React, { useState } from 'react'
import {
  Button,
  Grid,
  Paper,
  Popover,
  ButtonGroup,
  Typography,
  Slider,
  CircularProgress
} from '@material-ui/core'
import {
  Close as CloseIcon,
  LocationOn as LocationIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTranslation } from 'next-i18next'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-google-places-autocomplete'
import 'react-google-places-autocomplete/dist/index.min.css'
import { useRegion } from '../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    width: '100%',
    border: '2px solid white'
  },
  button: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none'
    }
  },
  filterButton: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '0.75rem'
  },
  textButton: {
    width: '100%'
  },
  expandedButtonGroup: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  expandedButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: 'none'
    }
  },
  collapsedButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  popover: {
    marginTop: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    minWidth: '320px',
    padding: theme.spacing(3, 3, 1.5, 3)
  },
  myLocationGroup: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#E3E5ED !important',
    border: '2px solid #E3E5ED'
  },
  myLocationButton: {
    backgroundColor: '#E3E5ED !important'
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  locationInputField: {
    marginBottom: theme.spacing(2),
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    paddingRight: '35px',
    paddingLeft: '16px',
    appearance: 'none',
    border: 'none',
    outline: 'none'
  },
  white: {
    color: 'white'
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  },
  overflowVisible: {
    overflow: 'visible'
  },
  '@media screen and (min-width: 380px)': {
    filterButton: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  '@media screen and (min-width: 600px)': {
    filterButton: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontSize: '0.875rem'
    }
  }
}))

export type SearchLocation = { lat: number; lng: number; radius: number }

type LocationPopoverProps = {
  location?: SearchLocation
  setLocation: (newLocation?: SearchLocation) => void
}

const extractLocationInfo = async (
  placesId: string
): Promise<{ lat: number; lng: number }> => {
  const results = await geocodeByPlaceId(placesId)
  const result = results[0]
  return {
    lat: (result.geometry.location.lat as any)(),
    lng: (result.geometry.location.lng as any)()
  }
}

const defaultRadius = 50000 // 50km

const LocationPopover: React.VFC<LocationPopoverProps> = ({
  location,
  setLocation
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const region = useRegion()

  const [usesGeoLocation, setUsesGeoLocation] = useState(false)
  const [loading, setLoading] = useState(false)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filterGeo'
  })

  const setCoordinates = (lat: number, lng: number) =>
    setLocation({ lat, lng, radius: location?.radius ?? defaultRadius })

  const setRadius = (radius: number) =>
    setLocation(location ? { ...location, radius } : undefined)

  const retrieveUserLocation = (data) => {
    setLoading(false)
    setUsesGeoLocation(true)
    setCoordinates(data.coords.latitude, data.coords.longitude)
  }

  const geoLocationError = () => {
    setLoading(false)
    window.alert(t('We are unable to determine your location.'))
  }

  const attemptGeolocation = () => {
    setLoading(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        retrieveUserLocation,
        geoLocationError,
        { timeout: 5000, enableHighAccuracy: true }
      )
    } else {
      geoLocationError()
    }
  }

  const resetCoordinates = () => {
    setLocation(undefined)
  }

  const setSliderRange = (event, radius) => setRadius(radius)

  const sliderText = (value) => `${value / 1000}km`

  return (
    <>
      <ButtonGroup
        className={`${classes.buttonGroup} ${
          popupState.isOpen ? classes.expandedButton : classes.collapsedButton
        } ${popupState.isOpen && classes.expandedButtonGroup} ${
          location && classes.active
        }`}
        disableRipple
      >
        <Button
          variant='contained'
          className={`${classes.filterButton} ${classes.textButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          {...bindTrigger(popupState)}
        >
          {t('Location')}
        </Button>
        <Button
          variant='contained'
          className={`${classes.filterButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          onClick={() => (location ? resetCoordinates() : popupState.open())}
          {...(location ? [] : bindTrigger(popupState))}
        >
          {location ? (
            <CloseIcon onClick={resetCoordinates} />
          ) : (
            <LocationIcon />
          )}
        </Button>
      </ButtonGroup>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popover}
        PaperProps={{
          className: `${classes.elevation} ${classes.overflowVisible}`
        }}
        elevation={0}
      >
        <Paper className={classes.paper}>
          <ButtonGroup
            className={`${classes.buttonGroup} ${classes.myLocationGroup}`}
            disableRipple
          >
            <Button
              variant='contained'
              className={`${classes.button} ${classes.textButton} ${classes.myLocationButton}`}
              onClick={attemptGeolocation}
              disabled={loading}
            >
              {t('My location')}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Button
              variant='contained'
              className={`${classes.button} ${classes.myLocationButton}`}
              onClick={resetCoordinates}
            >
              {usesGeoLocation && location ? (
                <CloseIcon color='primary' onClick={resetCoordinates} />
              ) : (
                <LocationIcon color='primary' />
              )}
            </Button>
          </ButtonGroup>
          <GooglePlacesAutocomplete
            apiKey='AIzaSyB1MloPHeTtY1HtpQNRugROEbShi69w43g'
            placeholder={t('City, zipcode or address')}
            onSelect={async (place) => {
              const info = await extractLocationInfo(place.place_id)
              setUsesGeoLocation(false)
              setCoordinates(info.lat, info.lng)
              if (window.screen.width <= 765) {
                popupState.close()
              }
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: [region]
              }
            }}
            onLoadFailed={console.error}
            inputClassName={classes.locationInputField}
          />
          {location && (
            <div className={classes.white}>
              <Typography variant='body1'>{t('Distance')}</Typography>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Slider
                    className={classes.white}
                    disabled={!location}
                    value={location?.radius}
                    getAriaValueText={sliderText}
                    aria-labelledby='step-slider'
                    id='step-slider'
                    onChange={setSliderRange}
                    step={5000}
                    min={5000}
                    max={100000}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography>{sliderText(location.radius)}</Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </Paper>
      </Popover>
    </>
  )
}

export default LocationPopover
