import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Theme } from '@material-ui/core'
import { ArrowUpward as ArrowUpIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '70px',
    height: '70px',
    borderRadius: '8px',
    backgroundColor: '#08135919',
    boxShadow: '0 3px 11px #08135914',
    color: theme.palette.primary.main,
    position: 'fixed',
    bottom: '100px',
    left: 'calc(90vw - 35px)',
    zIndex: 400,
    '&:hover': {
      backgroundColor: '#08135919'
    }
  },
  '@media screen and (max-width: 600px)': {
    button: {
      width: '48px',
      height: '48px'
    }
  }
}))

function ScrollToTopButton() {
  const classes = useStyles()

  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <IconButton onClick={goToTop} className={classes.button} disableRipple>
      <ArrowUpIcon />
    </IconButton>
  )
}

export default ScrollToTopButton
